

$(document).ready(function($){

    //================================
    //GENERIC VALIDATION
    //================================
    $.validate({
      borderColorOnError: '#ff5b4b',
      errorMessageClass: 'form-error',
      validateOnBlur: true,
      modules: 'date',
      scrollToTopOnError: false
    });


    //================================
    //DATE AND TIME WIDGETS
    //================================
    // Cache the needed fields for use below in the datepicker()
    let dateField = document.querySelector('.date-input');
    let timeField = document.querySelector('.time');
    let timeAbbrField = document.querySelector('.time-abbr');

    // Date and time pickers
    $('.date-input').datepicker({
      minDate: 1, // ensures that the first available date is tomorrow
      // We use the onClose setting to choose which time input to select based
      // on business rules.
      onClose: function(date) {
        let todaysDate = new Date();
        let formattedDate = ('0' + (todaysDate.getMonth() + 1)).slice(-2) + '/'
                          + ('0' + (todaysDate.getDate() + 1)).slice(-2) + '/'
                          + todaysDate.getFullYear();
        if (date === formattedDate) {
          timeField.classList.add('hidden');
          timeAbbrField.classList.remove('hidden');
        } else {
          timeAbbrField.classList.add('hidden');
          timeField.classList.remove('hidden');
        }
      }
    });
    // We use this timepicker() if the date selected is any date other than tomorrow.
    $('.time input').timepicker({
      className: 'time-list',
      disableTextInput: true,
      minTime: '8:00am',
      maxTime: '6:00pm',
      disableTouchKeyboard: true
    });
    // We use this alternate timepicker() if the date selected IS tomorrow.
    $('.time-abbr input').timepicker({
      className: 'time-list',
      disableTextInput: true,
      minTime: '12:00pm',
      maxTime: '6:00pm',
      disableTouchKeyboard: true
    });

    $('.timezone input').timepicker({
        className: 'timezone-list',
        disableTextInput: true,
        minTime: '0',
        maxTime: '0',
        'noneOption': [
            {
                'label': 'Eastern',
                'className': 'timezone-eastern',
                'value': 'Eastern'
            },
            {
                'label': 'Central',
                'className': 'timezone-central',
                'value': 'Central'
            },
            {
                'label': 'Mountain',
                'className': 'timezone-mountain',
                'value': 'Mountain'
            },
            {
                'label': 'Pacific',
                'className': 'timezone-pacific',
                'value': 'Pacific'
            }
        ]
    });


    //================================
    //Ebook Form
    //================================
    $(".ebook-form").submit(function(event){
        if (request) { request.abort();}
        var form = $(this);

        get_country(function(country){
            var fields = {};
            var data = form.serializeArray();
            for (var i=0;i<data.length;i++){
                fields[data[i].name] = data[i].value;
            }
            if(country == 'US' || !country){
                send_email(fields);
                display_success(form);
            }
        });
        event.preventDefault();
    });

    function get_country(cb){
        $.ajax("https://ipinfo.io/geo").done(function(data){
            if(data.country) cb(data.country);
            else cb("");
        }).fail(function(){
            cb("");
        });
    }

    function send_email(data){
        var message = "Berkshire Settlements Ebook Request:\n\n";
        message += "Name: "+data.Name+"\n";
        message += "Email: "+data.Email+"\n";
        message += "Phone: "+data.Phone+"\n";
        message += "Address: "+data.Address+"\n";
        var data = {
            action: 'ebook',
            message: message
        };
        $.post(ajaxurl, data, function(response) {
            console.log("done");
        });
    }

    //================================
    //SUBMISSION TO GOOGLE SHEETS
    //================================
    //using this method: https://github.com/dwyl/web-form-to-google-sheet

    // variable to hold request
    var request;
    // bind to the submit event of our form
    $(".form-form").submit(function(event){

        //TESTING
        // var $form = $(this);
        // display_success($form);
        // event.preventDefault();



        // abort any pending request
        if (request) { request.abort();}
        // setup some local variables
        var $form = $(this);
        // let's select and cache all the fields
        var $inputs = $form.find("input, select, button, textarea");
        // serialize the data in the form
        var serializedData = $form.serialize();
        var data = $form.serializeArray();
        // let's disable the inputs for the duration of the ajax request
        // Note: we disable elements AFTER the form data has been serialized.
        // Disabled form elements will not be serialized.
        $inputs.prop("disabled", true);
        //grab the appropriate spreadsheet, and correct success area
        var spreadsheet = $(this).data('spreadsheet');
        var success = $(this).data('success');
        var parent = $(this).data('parent');
        var endpoint = "https://script.google.com/a/macros/berkshiresettlements.com/s/" + spreadsheet + "/exec";
        if(spreadsheet.includes('http')) endpoint = spreadsheet;
        var item = data.find(function(item){ return item.name == "key" });
        if((!item || item.value.length < 1)){
            $('#result').text('Sending data...');
            // fire off the request to /form.php

            request = $.ajax({
            url: endpoint,  // clone
                type: "post",
                data: serializedData
            });
            // callback handler that will be called on success
            request.done(function (response, textStatus, jqXHR){
              // log a message to the console
              console.log(response, textStatus, jqXHR);
              //$('#result').html('<a href="https://docs.google.com/spreadsheets/d/10tt64TiALYhPMqR2fh9JzkuhxW7oC0rXXPb_pmJ7HAY/edit?usp=sharing" target="_blank">Success - see Google Sheet</a>');
              //reset the form
            //   $('#' + parent).fadeOut();
            //   $('#' + success).fadeIn();
              display_success($form);
            });
            // callback handler that will be called on failure
            request.fail(function (jqXHR, textStatus, errorThrown){
              // log the error to the console
              console.error(
                  "The following error occured: "+
                  textStatus, errorThrown
              );
            });
            // callback handler that will be called regardless
            // if the request failed or succeeded
            request.always(function () {
              // reenable the inputs
              $inputs.prop("disabled", false);
            });
            // prevent default posting of form
            event.preventDefault();
        }

    });

    //================================
    //Toggle state
    //================================
    function display_success(form, message){
        var success_el = $(form).parent().find('.form-success').first();
        success_el.closest(".modal-content").addClass('modal-success');

        //match the height
        //success_el.css("min-height", $(form).height()+"px");
        $(form).toggleClass('hidden');
        success_el.toggleClass('hidden');
    }

});
