
(function($){

    //make accordians exclusive (one item open and others close)
    var accordian = $('.accordian');
    accordian.on('show.bs.collapse','.collapse', function() {
        accordian.find('.collapse.in').collapse('hide');
    });


    //match heght on info modules
    $('.info-list').each(function(){
        var heading = $(this).find('.list-group__heading');
        var body = $(this).find('.list-group__body');
        console.log(body.length)
        heading.matchHeight();
        body.matchHeight();
    });


})(jQuery);
