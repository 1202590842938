/**
 *
 *  Testimonials page toggle functionality
 *
 */
 function getParameterByName(name, url) {
     if (!url) {
       url = window.location.href;
     }
     name = name.replace(/[\[\]]/g, "\\$&");
     var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
         results = regex.exec(url);
     if (!results) return null;
     if (!results[2]) return '';
     return decodeURIComponent(results[2].replace(/\+/g, " "));
 }

(function($){

    var side_bar_items = $(".testimonials-sidebar .item");
    var content_items = $(".testimonials-content .item");

    //permalink to testimonial a using query string
    var testimonial = getParameterByName("testimonial");
    //show the active testimonial
    if(testimonial){
        //desktop
        var content = $(".testimonials-content .item[data-story='"+testimonial+"']");
        var button = $(".testimonials-sidebar .item[data-story='"+testimonial+"']");
        content_items.removeClass("active");
        side_bar_items.removeClass("active");
        content.addClass("active");
        button.addClass("active");


        //mobile
        var item_headline = $(".accordian-item[data-story='"+testimonial+"'] h4 a");
        var item_body = $(".accordian-item[data-story='"+testimonial+"'] .accordian-item__body");
        item_headline.removeClass('collapsed');
        item_body.addClass('in');

        //$.scrollTo(item_headline);
    }

    //bind side nav buttons
    side_bar_items.on('click', function(e){
        //clicked sidebar item
        var item = $(e.currentTarget);
        //corresponsing content div
        var content = $('#'+item.data('content'));

        content_items.removeClass("active");
        side_bar_items.removeClass("active");
        content.addClass("active");
        item.addClass("active");

        //var top = $('testimonials-content').offset()

        $.scrollTo($('.testimonials-content'));
    });

    //fixed side nav
    // if($('.testimonials-sidebar').length > 0){
    //     $(window).scroll(function(e){
    //         //sidebar
    //         var el = $('.testimonials-sidebar');
    //         //sidebar conteiner
    //         var container = $('.testimonials-content');
    //         //offset top
    //         var offset_top = container.offset().top + 150;
    //         //dont add move the sidebar past this point
    //         var max = container.height() - el.height();
    //         //current scroll position
    //         var pos = $(this).scrollTop();
    //         // at the top not fixed
    //         if(pos < offset_top){
    //             el.css('transform', "translateY(0px)");
    //         }
    //         // in the middle sidebar is fixed
    //         if(pos > offset_top){
    //             var top = (pos - offset_top) + 10;
    //             top = (top > max) ? max : top;
    //             el.css('transform', "translateY("+top+"px)");
    //         }
    //     });
    // }

})(jQuery);
