/**
 *
 * Download the forms for a state
 *
 */
(function($){


    // $('.download-forms').on('click', function(){
    //     if($("#state-select option:selected").val()){
    //         $('#state-modal').modal('show');
    //     }
    // })

    $('.select-dropdown .dropdown-menu li').on('click', function(e){
        var state = $(this);
        //update button text
        $('#state-field').html(state.html());
        //update modal
        $('.state-name').html(state.html());
        $('.state-download-link').html("Download Forms (" + state.data('abr') + ")");
        $('.state-download-link').attr('href', state.data('link'));
        $('.state-info').html(state.data('info'));
    });

    //modal
    $('.download-forms').on('click', function(){
        if($('#state-field').html() != "Select a State" ){
            $('#state-modal').modal('show');
        }
    });

    //update modal with chosen state info
    // $('#state-modal').on('show.bs.modal', function (e) {
    //     var active_option = $("#state-select option:selected");
    //     // $('.state-name').html(active_option.html());
    //     // $('.state-download-link').html("Download Forms (" + active_option.val() + ")");
    //     // $('.state-download-link').attr('href', active_option.data('link'));
    //     // $('.state-info').html(active_option.data('info'));
    // })

})(jQuery);
